import React, { useEffect, useState } from "react";
import { dashboardBannerAPI } from "../service/AuthService";
import { useDispatch } from "react-redux";
import store from "../redux/store";
import { Link } from "react-router-dom";
import { PERMISSION_MAP } from "../utils/constants";


export default function Dashboard() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [homeImages, setHomeImages] = React.useState([]);
  const userData = store.getState().account.userData;
  const getBannerImages = async () => {
    var { response, code } = await dashboardBannerAPI();
    if (code === 200) {
      setHomeImages(response);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getBannerImages();
  }, []);

  const userPermissions = PERMISSION_MAP[userData?.userType?.toUpperCase()];
  const renderComponent = (link, backgroundColor, text, number) => (
    <div className="col-lg-6 col-12" key={link}>
      <a href={link}></a>
      <div className={`small-box ${backgroundColor}`}>
        <a href={link}>
          <div className="inner" style={{ padding: 12 }}>
            <h3>{number}</h3>
            <p>{text}</p>
          </div>
          <div className="icon">
            <i className="ion ion-bag" />
          </div>
        </a>
        <a href={link} className="small-box-footer">
          More Info <i className="fas fa-arrow-circle-right" />
        </a>
      </div>
    </div>
  );

  return (

    <div _ngcontent-ng-c3705319800="" className="main_content">
  <router-outlet _ngcontent-ng-c3705319800="" />
  <app-dashboard _nghost-ng-c2891388947="">
    <div _ngcontent-ng-c2891388947="" className="page_header">
      <h1 _ngcontent-ng-c2891388947="" className="page_heading">
        Dashboard
      </h1>
      <div _ngcontent-ng-c2891388947="" className="breadcrumb_block">
        <nav _ngcontent-ng-c2891388947="" aria-label="breadcrumb">
          <ol _ngcontent-ng-c2891388947="" className="breadcrumb">
            <li _ngcontent-ng-c2891388947="" className="breadcrumb-item">
              <a _ngcontent-ng-c2891388947="" href="#/dashboard">
                Home
              </a>
            </li>
            <li
              _ngcontent-ng-c2891388947=""
              aria-current="page"
              className="breadcrumb-item active"
            >
              Dashboard
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div _ngcontent-ng-c2891388947="" className="px-md-0 px-2">
      <div _ngcontent-ng-c2891388947="" className="row g-3">
        <div
          _ngcontent-ng-c2891388947=""
          className="col-6 col-sm-6 col-md-6 col-lg-4 col-xxl-3"
        >
          <div _ngcontent-ng-c2891388947="" className="dash_box">
            <a _ngcontent-ng-c2891388947="" href="#/tech/tech-dashboard">
              <div
                _ngcontent-ng-c2891388947=""
                className="dash_icon_box light_yellow"
              >
                <svg _ngcontent-ng-c2891388947="">
                  <use _ngcontent-ng-c2891388947="" xlinkHref="#account" />
                </svg>
              </div>
              <h2 _ngcontent-ng-c2891388947="">Admin Details</h2>
            </a>
          </div>
        </div>
        <div
          _ngcontent-ng-c2891388947=""
          className="col-6 col-sm-6 col-md-6 col-lg-4 col-xxl-3"
        >
          <div _ngcontent-ng-c2891388947="" className="dash_box">
            <a _ngcontent-ng-c2891388947="" href="#/sports/details">
              <div
                _ngcontent-ng-c2891388947=""
                className="dash_icon_box light_blue"
              >
                <svg _ngcontent-ng-c2891388947="">
                  <use _ngcontent-ng-c2891388947="" xlinkHref="#football" />
                </svg>
              </div>
              <h2 _ngcontent-ng-c2891388947="">Sport's Betting</h2>
            </a>
          </div>
        </div>
        <div
          _ngcontent-ng-c2891388947=""
          className="col-6 col-sm-6 col-md-6 col-lg-4 col-xxl-3"
        >
          <div _ngcontent-ng-c2891388947="" className="dash_box">
            <a _ngcontent-ng-c2891388947="" href="#/ledger/ledger-dashboard">
              <div
                _ngcontent-ng-c2891388947=""
                className="dash_icon_box light_green"
              >
                <svg _ngcontent-ng-c2891388947="">
                  <use _ngcontent-ng-c2891388947="" xlinkHref="#file" />
                </svg>
              </div>
              <h2 _ngcontent-ng-c2891388947="">Ledger</h2>
            </a>
          </div>
        </div>
        <div
          _ngcontent-ng-c2891388947=""
          className="col-6 col-sm-6 col-md-6 col-lg-4 col-xxl-3"
        >
          <div _ngcontent-ng-c2891388947="" className="dash_box">
            <a _ngcontent-ng-c2891388947="" href="#/cash/cash-dashboard">
              <div
                _ngcontent-ng-c2891388947=""
                className="dash_icon_box light_orange"
              >
                <svg _ngcontent-ng-c2891388947="">
                  <use _ngcontent-ng-c2891388947="" xlinkHref="#money" />
                </svg>
              </div>
              <h2 _ngcontent-ng-c2891388947="">Cash Transaction</h2>
            </a>
          </div>
        </div>
      </div>
      <div _ngcontent-ng-c2891388947="" className="Betting_tbl">
        <div _ngcontent-ng-c2891388947="" className="row g-3">
          <div
            _ngcontent-ng-c2891388947=""
            className="col-sm-12 col-md-6 col-lg-6"
          >
            <div _ngcontent-ng-c2891388947="" className="bet_tbl_inner_block">
              <div _ngcontent-ng-c2891388947="" className="bet_tbl_header">
                <img
                  _ngcontent-ng-c2891388947=""
                  alt=""
                  className="imgWidth"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://admin.jmd15.com/assets/img/4.avif"
                />
                <h5 _ngcontent-ng-c2891388947="">Cricket</h5>
                <div _ngcontent-ng-c2891388947="" className="inplay_dot">
                  {" "}
                  Inplay
                  <span _ngcontent-ng-c2891388947="" />
                </div>
              </div>
              <div _ngcontent-ng-c2891388947="" className="bettbl_body">
                <div
                  _ngcontent-ng-c2891388947=""
                  className="bet_tbl_row inplay-bg"
                >
                  <div _ngcontent-ng-c2891388947="" className="bet_col_70">
                    <span _ngcontent-ng-c2891388947="" className="team_name">
                      England
                    </span>
                  </div>
                  <div _ngcontent-ng-c2891388947="" className="bet_col_30">
                    <div _ngcontent-ng-c2891388947="" className="btn_group">
                      <a
                        _ngcontent-ng-c2891388947=""
                        href="javascript:void(0)"
                        className="back btn"
                      >
                        <span _ngcontent-ng-c2891388947="">0.39</span>
                      </a>
                      <a
                        _ngcontent-ng-c2891388947=""
                        href="javascript:void(0)"
                        className="lay btn"
                      >
                        <span _ngcontent-ng-c2891388947="">0.40</span>
                      </a>
                    </div>
                  </div>
                </div>
                {/**/}
                <div
                  _ngcontent-ng-c2891388947=""
                  className="bet_tbl_row inplay-bg"
                >
                  <div _ngcontent-ng-c2891388947="" className="bet_col_70">
                    <span _ngcontent-ng-c2891388947="" className="team_name">
                      Sri Lanka
                    </span>
                  </div>
                  <div _ngcontent-ng-c2891388947="" className="bet_col_30">
                    <div _ngcontent-ng-c2891388947="" className="btn_group">
                      <a
                        _ngcontent-ng-c2891388947=""
                        href="javascript:void(0)"
                        className="back btn"
                      >
                        <span _ngcontent-ng-c2891388947="">7.60</span>
                      </a>
                      <a
                        _ngcontent-ng-c2891388947=""
                        href="javascript:void(0)"
                        className="lay btn"
                      >
                        <span _ngcontent-ng-c2891388947="">7.80</span>
                      </a>
                    </div>
                  </div>
                </div>
                {/**/}
                <div
                  _ngcontent-ng-c2891388947=""
                  className="bet_tbl_row inplay-bg"
                >
                  <div _ngcontent-ng-c2891388947="" className="bet_col_70">
                    <span _ngcontent-ng-c2891388947="" className="team_name">
                      The Draw
                    </span>
                  </div>
                  <div _ngcontent-ng-c2891388947="" className="bet_col_30">
                    <div _ngcontent-ng-c2891388947="" className="btn_group">
                      <a
                        _ngcontent-ng-c2891388947=""
                        href="javascript:void(0)"
                        className="back btn"
                      >
                        <span _ngcontent-ng-c2891388947="">4.80</span>
                      </a>
                      <a
                        _ngcontent-ng-c2891388947=""
                        href="javascript:void(0)"
                        className="lay btn"
                      >
                        <span _ngcontent-ng-c2891388947="">4.90</span>
                      </a>
                    </div>
                  </div>
                </div>
                {/**/}
                {/**/}
              </div>
            </div>
          </div>
          {/**/}
          <div
            _ngcontent-ng-c2891388947=""
            className="col-sm-12 col-md-6 col-lg-6"
          >
            <div _ngcontent-ng-c2891388947="" className="bet_tbl_inner_block">
              <div _ngcontent-ng-c2891388947="" className="bet_tbl_header">
                <img
                  _ngcontent-ng-c2891388947=""
                  alt=""
                  className="imgWidth"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://admin.jmd15.com/assets/img/4.avif"
                />
                <h5 _ngcontent-ng-c2891388947="">Cricket</h5>
                <div _ngcontent-ng-c2891388947="" className="inplay_dot">
                  {" "}
                  Inplay
                  <span _ngcontent-ng-c2891388947="" />
                </div>
              </div>
              <div _ngcontent-ng-c2891388947="" className="bettbl_body">
                <div
                  _ngcontent-ng-c2891388947=""
                  className="bet_tbl_row inplay-bg"
                >
                  <div _ngcontent-ng-c2891388947="" className="bet_col_70">
                    <span _ngcontent-ng-c2891388947="" className="team_name">
                      Lucknow Falcons
                    </span>
                  </div>
                  <div _ngcontent-ng-c2891388947="" className="bet_col_30">
                    <div _ngcontent-ng-c2891388947="" className="btn_group">
                      <a
                        _ngcontent-ng-c2891388947=""
                        href="javascript:void(0)"
                        className="back btn"
                      >
                        <span _ngcontent-ng-c2891388947="">0.00</span>
                      </a>
                      <a
                        _ngcontent-ng-c2891388947=""
                        href="javascript:void(0)"
                        className="lay btn"
                      >
                        <span _ngcontent-ng-c2891388947="">0.00</span>
                      </a>
                    </div>
                  </div>
                </div>
                {/**/}
                <div
                  _ngcontent-ng-c2891388947=""
                  className="bet_tbl_row inplay-bg"
                >
                  <div _ngcontent-ng-c2891388947="" className="bet_col_70">
                    <span _ngcontent-ng-c2891388947="" className="team_name">
                      Kashi Rudras
                    </span>
                  </div>
                  <div _ngcontent-ng-c2891388947="" className="bet_col_30">
                    <div _ngcontent-ng-c2891388947="" className="btn_group">
                      <a
                        _ngcontent-ng-c2891388947=""
                        href="javascript:void(0)"
                        className="back btn"
                      >
                        <span _ngcontent-ng-c2891388947="">0.00</span>
                      </a>
                      <a
                        _ngcontent-ng-c2891388947=""
                        href="javascript:void(0)"
                        className="lay btn"
                      >
                        <span _ngcontent-ng-c2891388947="">0.00</span>
                      </a>
                    </div>
                  </div>
                </div>
                {/**/}
                {/**/}
              </div>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
      </div>
    </div>
  </app-dashboard>
  {/**/}
</div>


  );
}
