import React, { useEffect } from "react";
import { getBetHistoryAPI, searchUserAPI } from "../service/AuthService";
import AsyncSelect from "react-select/async";
import moment from "moment";

export default function BetHistory() {
  const [from, setFrom] = React.useState(
    moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD")
  );
  const [to, setTo] = React.useState(
    moment()
      .add(1, "d")
      .format("YYYY-MM-DD")
  );
  const [sport, setSport] = React.useState("CRICKET");
  const [matched, setMatched] = React.useState("MATCHED");
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const getBetHistory = async () => {
    try {
      let payload = {
        from: new Date(from),
        matched: matched == "MATCHED" ? true : false,
        page,
        size,
        sport,
        to: new Date(to),
        userName: userName?.value?.split(" ")[0],
      };
      const {
        response: { content },
      } = await getBetHistoryAPI(payload);
      let data_ = content.map((item, index) => ({
        ...item,

        bet_type: item.back_rate || item.back_run ? "Back" : "Lay",
        rate: item.back_run ? item.back_run : item.lay_run,
        "p/l": item.profit ? item.profit : item.loss,
      }));
      setData(data_);
    } catch (e) {
      setData([]);
    }
  };
  const searchClient = (inputValue) => {
    return searchUserAPI(inputValue).then((response) => {
      return response.response.map((userName) => ({
        value: userName,
        label: userName,
      }));
    });
  };
  useEffect(() => {
    getBetHistory();
  }, [size,page]);
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
     
      <app-bet-history _nghost-ng-c4006867332="" className="ng-star-inserted">
        <div _ngcontent-ng-c4006867332="" _="" className="page_header">
          <h1 _ngcontent-ng-c4006867332="" _="" className="page_heading">
            Bet History
          </h1>
          <div _ngcontent-ng-c4006867332="" _="" className="breadcrumb_block">
            <nav _ngcontent-ng-c4006867332="" _="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c4006867332="" _="" className="breadcrumb">
                <li
                  _ngcontent-ng-c4006867332=""
                  _=""
                  className="breadcrumb-item"
                >
                  <a
                    _ngcontent-ng-c4006867332=""
                    _=""
                    ng-reflect-router-link="/dashboard"
                    href="#/dashboard"
                  >
                    Home
                  </a>
                </li>
                <li
                  _ngcontent-ng-c4006867332=""
                  _=""
                  className="breadcrumb-item"
                >
                  <a
                    _ngcontent-ng-c4006867332=""
                    _=""
                    ng-reflect-router-link="/tech/tech-dashboard"
                    href="#/tech/tech-dashboard"
                  >
                    Tech Admin Details
                  </a>
                </li>
                <li
                  _ngcontent-ng-c4006867332=""
                  _=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Sub Admin Details{" "}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c4006867332=""
          className="Super_master_details_section agentledger_block"
        >
          <form
            _ngcontent-ng-c4006867332=""
            noValidate=""
            className="row g-lg-3 g-2 align-items-end mb-4 ng-untouched ng-pristine ng-valid"
          >
            <div
              _ngcontent-ng-c4006867332=""
              className="col-6 col-md-4 col-lg-2"
            >
              <div _ngcontent-ng-c4006867332="" className="input_block">
                <label _ngcontent-ng-c4006867332="" htmlFor="type">
                  Chosse Type
                </label>
                <select
                  onChange={(e) => {
                    setMatched(e.target.value);
                  }}
                  value={matched}
                  _ngcontent-ng-c4006867332=""
                  name="bet_type"
                  className="form-control form-select ng-untouched ng-pristine ng-valid"
                >
                  <option value="ALL">Bet Status</option>
                  <option value="MATCHED">Matched</option>
                  <option value="DELETED">Deleted</option>
                </select>
              </div>
            </div>
            <div
              _ngcontent-ng-c4006867332=""
              className="col-6 col-md-4 col-lg-2"
            >
              <div
                _ngcontent-ng-c4006867332=""
                className="input_block position-relative"
              >
                <label _ngcontent-ng-c4006867332="">User Name</label>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={searchClient}
                  onChange={(e) => setUserName(e)}
                  isClearable={true}
                  value={userName}
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c4006867332=""
              className="col-6 col-md-4 col-lg-2"
            >
              <div _ngcontent-ng-c4006867332="" className="input_block">
                <label _ngcontent-ng-c4006867332="">Select Sport</label>
                <select
                  _ngcontent-ng-c4006867332=""
                  value={sport}
                  onChange={(e) => setSport(e.target.value)}
                  name="sportId"
                  className="form-control form-select ng-untouched ng-pristine ng-valid"
                >
                  <option _ngcontent-ng-c4006867332="" value="">
                    Select
                  </option>
                  <option value="ALL">Sports Type</option>
                  <option value="CRICKET">Cricket</option>
                  <option value="SOCCER">Football</option>
                  <option value="TENNIS">Tennis</option>
                  <option value="CASINO">Casino</option>
                </select>
              </div>
            </div>
            <div
              _ngcontent-ng-c4006867332=""
              className="col-6 col-md-4 col-lg-2"
            >
              <div _ngcontent-ng-c4006867332="" className="input_block">
                <label _ngcontent-ng-c4006867332="">From</label>
                <input
                  _ngcontent-ng-c4006867332=""
                  type="date"
                  placeholder="from date"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                  name="fromDate"
                  className="form-control ng-untouched ng-pristine ng-valid"
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c4006867332=""
              className="col-6 col-md-4 col-lg-2"
            >
              <div _ngcontent-ng-c4006867332="" className="input_block">
                <label _ngcontent-ng-c4006867332="">To</label>
                <input
                  _ngcontent-ng-c4006867332=""
                  type="date"
                  placeholder="from date"
                  name="toDate"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                  className="form-control ng-untouched ng-pristine ng-valid"
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c4006867332=""
              className="col-6 col-md-6 col-lg-2"
            >
              <div _ngcontent-ng-c4006867332="" className="input_block">
                <button
                  _ngcontent-ng-c4006867332=""
                  type="button"
                  onClick={getBetHistory}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
          <div _ngcontent-ng-c4006867332="" className="custom_table">
            <div
              _ngcontent-ng-c4006867332=""
              className="d-flex justify-content-between align-items-center mb-3 ng-star-inserted"
            >
              <div _ngcontent-ng-c4006867332="" className="shoe_entery" />
              <div
                _ngcontent-ng-c4006867332=""
                id="clientListTable_filter"
                className="dataTables_filter"
              >
                <label _ngcontent-ng-c4006867332="">
                  Search:{" "}
                  <input
                    _ngcontent-ng-c4006867332=""
                    type="search"
                    placeholder=""
                    aria-controls="clientListTable"
                    name="MatchAmount"
                    id="sessionAmount"
                    size={10}
                    autoComplete="OFF"
                    autofocus=""
                    className="form-control datatable-search ng-untouched ng-pristine ng-valid"
                  />
                </label>
              </div>
            </div>
            <div
              _ngcontent-ng-c4006867332=""
              className="table-responsive ng-star-inserted"
            >
              <table _ngcontent-ng-c4006867332="" className="table">
                <thead _ngcontent-ng-c4006867332="">
                  <tr _ngcontent-ng-c4006867332="">
                    <th _ngcontent-ng-c4006867332="">Event Type</th>
                    <th _ngcontent-ng-c4006867332="">Event Name</th>
                    <th _ngcontent-ng-c4006867332="">Parent Name</th>
                    <th _ngcontent-ng-c4006867332="">Username</th>
                    <th _ngcontent-ng-c4006867332="">Runner Name</th>
                    <th _ngcontent-ng-c4006867332="">Run/Bhav</th>
                    <th _ngcontent-ng-c4006867332="">Stack</th>
                    <th _ngcontent-ng-c4006867332="">Win/Loss</th>
                    <th _ngcontent-ng-c4006867332="">P&amp;L</th>
                    <th _ngcontent-ng-c4006867332="">Place Date</th>
                  </tr>
                </thead>
                <tbody _ngcontent-ng-c4006867332="">
                  {data?.map((item) => (
                    <tr
                      _ngcontent-ng-c4006867332=""
                      className={`odd ${
                        item?.lay_run || item?.lay_rate ? "lay" : "back"
                      } ng-star-inserted`}
                    >
                      <td _ngcontent-ng-c4006867332="">{item?.marketName}</td>
                      <td _ngcontent-ng-c4006867332="">{item?.event_name} </td>
                      <td _ngcontent-ng-c4006867332="">{item?.parentName}</td>
                      <td _ngcontent-ng-c4006867332="">{item?.userName}</td>
                      <td _ngcontent-ng-c4006867332="">{item?.runner_name}</td>
                      <td _ngcontent-ng-c4006867332="">{item?.rate}</td>
                      <td _ngcontent-ng-c4006867332="">{item?.amount}</td>
                      <td _ngcontent-ng-c4006867332="">
                        {item?.loss ? "Loss" : "Win"}
                      </td>
                      <td _ngcontent-ng-c4006867332="">-{item?.pl}</td>
                      <td _ngcontent-ng-c4006867332="">
                        <div
                          _ngcontent-ng-c4006867332=""
                          className="Date_time_box"
                        >
                          {moment(item?.created_date).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div
                _ngcontent-ng-c4006867332=""
                className="d-flex justify-content-center"
                style={{ textAlign: "center" }}
              >
                <div
                  _ngcontent-ng-c4006867332=""
                  className="col-sm-12 ng-star-inserted"
                >
                  <pagination-controls
                    _ngcontent-ng-c4006867332=""
                    className="my-pagination"
                  >
                    <pagination-template>
                      <nav role="navigation" aria-label="Pagination">
                        <ul className="ngx-pagination ng-star-inserted">
                          <li className={`"pagination-previous disabled ng-star-inserted`}>
                            {/**/}
                            <span
                              aria-disabled="true"
                              onClick={() => {
                                if (page > 0) setPage(page - 1);
                              }}
                              className="ng-star-inserted"
                            >
                              {" "}
                              Previous <span className="show-for-sr">page</span>
                            </span>
                            {/**/}
                          </li>
                          {/**/}
                          <li className="small-screen"> {page / size} </li>
                          <li className="current ng-star-inserted">
                            {/**/}
                            <span
                              aria-live="polite"
                              className="ng-star-inserted"
                            >
                              <span className="show-for-sr">
                                You're on page{" "}
                              </span>
                              <span>{page + 1}</span>
                            </span>
                            {/**/}
                            {/**/}
                          </li>
                          <li className="ng-star-inserted">
                            <a tabIndex={0} className="ng-star-inserted">
                              <span className="show-for-sr">page </span>
                              <span>{page + 2}</span>
                            </a>
                            {/**/}
                            {/**/}
                          </li>

                          <li className="pagination-next ng-star-inserted">
                            <a
                              onClick={
                                () => {
                                  if (data.length == size) {
                                    setPage(page + 1);
                                  }
                                }
                              }
                              tabIndex={0}
                              className="ng-star-inserted"
                            >
                              {" "}
                              Next <span className="show-for-sr">page</span>
                            </a>
                           
                          </li>
                          {/**/}
                        </ul>
                        {/**/}
                      </nav>
                    </pagination-template>
                  </pagination-controls>
                </div>
                {/**/}
                {/**/}
              </div>
            </div>
            {/**/}
            {/**/}
          </div>
        </div>
      </app-bet-history>
      {/**/}
    </div>
  );
}
