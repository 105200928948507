import JSEncrypt from "jsencrypt";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { loginAPI } from "../service/AuthService";
import {
  APP_NAME,
  PUBLIC_KEY,
  domainMap,
  generateFingerprint,
} from "../utils/constants";
import { generateHash } from "../service";

function LoginPage() {
  const dispatch = useDispatch();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const login = async (e) => {
    let encryptedPassword = new JSEncrypt();
    encryptedPassword.setPublicKey(PUBLIC_KEY);
    encryptedPassword = encryptedPassword.encrypt(password);
    let fingerprint = await generateHash();
    localStorage.setItem("fingerprint", fingerprint);
    var { data, code, message, response, error } = await loginAPI(
      userName,
      encryptedPassword
    );
    if (code === 200) {
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: false,
      });
      window.location.href = "/";
    } else if (code == 500) {
      toast.error(response);
    } else if (code == 403) {
      toast.error(error?.description + " contact admin");
    } else if (code == 401) {
      toast.error(response);
    } else {
      toast.error("Something went wrong or Account is not active");
    }
  };
  /*  const location = useLocation(); */

  const currentLevel = window.location.hostname.split(".")[0];
  return (
    <section _ngcontent-ng-c2998268805="" className="login_wrapper">
      <div _ngcontent-ng-c2998268805="" className="container">
        <div _ngcontent-ng-c2998268805="" className="top_logo_image">
          <img _ngcontent-ng-c2998268805="" src="assets/img/home_logo.png" />
        </div>
        <div _ngcontent-ng-c2998268805="" className="login_form_modal">
          <div _ngcontent-ng-c2998268805="" className="logo_block" />
          <div _ngcontent-ng-c2998268805="" className="form_heading">
            <h2 _ngcontent-ng-c2998268805="">login</h2>
          </div>
          <div
            _ngcontent-ng-c2998268805=""
            noValidate=""
            className="ng-untouched ng-pristine ng-valid"
          >
            <div _ngcontent-ng-c2998268805="" className="form-floating mb-4">
              <div _ngcontent-ng-c2998268805="" className="input_icon">
                <svg _ngcontent-ng-c2998268805="">
                  <use _ngcontent-ng-c2998268805="" xlinkHref="#userinput" />
                </svg>
              </div>
              <input
                _ngcontent-ng-c2998268805=""
                type="email"
                formcontrolname="user_name"
                name="username"
                id="floatingInput"
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Rahuljaj707@crickadda.com"
                className="form-control ng-untouched ng-pristine ng-valid"
              />
              <label _ngcontent-ng-c2998268805="" htmlFor="floatingInput">
                Username
              </label>
            </div>
            <div _ngcontent-ng-c2998268805="" className="form-floating mb-4">
              <div _ngcontent-ng-c2998268805="" className="input_icon">
                <svg _ngcontent-ng-c2998268805="">
                  <use _ngcontent-ng-c2998268805="" xlinkHref="#padlock" />
                </svg>
              </div>
              <input
                _ngcontent-ng-c2998268805=""
                type="password"
                name="password"
                formcontrolname="password"
                onChange={(e) => setPassword(e.target.value)}
                id="floatingPassword"
                placeholder="Password"
                className="form-control ng-untouched ng-pristine ng-valid"
              />
              <label _ngcontent-ng-c2998268805="" htmlFor="floatingPassword">
                Password
              </label>
            </div>
            <div _ngcontent-ng-c2998268805="" className="login_btn">
              <button
                onClick={login}
                _ngcontent-ng-c2998268805=""
                type="submit"
                className="btn btn-primary"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default LoginPage;
