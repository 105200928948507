import React, { useEffect, useState } from "react";
import {
  getCommissionReportAPI,
  resetCommissionReportByUserAPI,
} from "../service/AuthService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function ComissionLenaDena() {
  const [data, setData] = useState();
  const navigate=useNavigate();
  const getCommissionReport = async () => {
    const { response, code } = await getCommissionReportAPI();
    if (code == 200) {
      setData(response.commissionReport);
    }
  };
  const resetComm = async (clientId) => {
    const { response, code } = await resetCommissionReportByUserAPI(clientId);
    if (code == 200) {
      toast.success("Commission Reset Successfully");
      getCommissionReport();
    }
  };
  useEffect(() => {
    getCommissionReport();
  }, []);
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
    <router-outlet _ngcontent-ng-c3705319800="" />
    <app-commission-lena-dena
      _nghost-ng-c2164784703=""
      className="ng-star-inserted"
    >
      <div _ngcontent-ng-c2164784703="" className="page_header">
        <h1 _ngcontent-ng-c2164784703="" className="page_heading">
          Commission Lena Dena
        </h1>
        <div _ngcontent-ng-c2164784703="" className="breadcrumb_block">
          <nav _ngcontent-ng-c2164784703="" aria-label="breadcrumb">
            <ol _ngcontent-ng-c2164784703="" className="breadcrumb">
              <li _ngcontent-ng-c2164784703="" className="breadcrumb-item">
                <a _ngcontent-ng-c2164784703="" href="#/dashboard">
                  Home
                </a>
              </li>
              <li
                _ngcontent-ng-c2164784703=""
                aria-current="page"
                className="breadcrumb-item active"
              >
                Commission Lena Dena
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div
        _ngcontent-ng-c2164784703=""
        className="Super_master_details_section agentledger_block"
      >
        <div
          _ngcontent-ng-c2164784703=""
          className="row g-3 align-items-end mb-4"
        >
          <div
            _ngcontent-ng-c2164784703=""
            className="col-sm-12 col-md-6 col-lg-3"
          >
            <div
              _ngcontent-ng-c2164784703=""
              className="input_block position-relative"
            >
              <label _ngcontent-ng-c2164784703="">Select Client</label>
              <input
                _ngcontent-ng-c2164784703=""
                type="text"
                id="name"
                name="name"
                className="form-control"
              />
              {/**/}
            </div>
          </div>
          <div _ngcontent-ng-c2164784703="" className="col-md-6 col-lg-3 col-6">
            <div _ngcontent-ng-c2164784703="" className="input_block">
              <label _ngcontent-ng-c2164784703="">Date From</label>
              <input
                _ngcontent-ng-c2164784703=""
                type="date"
                id="name"
                name="name"
                className="form-control ng-valid ng-dirty ng-touched"
              />
            </div>
          </div>
          <div _ngcontent-ng-c2164784703="" className="col-md-6 col-lg-3 col-6">
            <div _ngcontent-ng-c2164784703="" className="input_block">
              <label _ngcontent-ng-c2164784703="">Date To</label>
              <input
                _ngcontent-ng-c2164784703=""
                type="date"
                id="name"
                name="name"
                className="form-control ng-untouched ng-pristine ng-valid"
              />
            </div>
          </div>
          <div
            _ngcontent-ng-c2164784703=""
            className="col-sm-12 col-md-6 col-lg-3"
          >
            <div _ngcontent-ng-c2164784703="" className="input_block">
              <button
                _ngcontent-ng-c2164784703=""
                type="button"
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div _ngcontent-ng-c2164784703="" className="custom_table">
          <div _ngcontent-ng-c2164784703="" className="table-responsive">
            <table _ngcontent-ng-c2164784703="" className="table">
              <thead _ngcontent-ng-c2164784703="">
                <tr _ngcontent-ng-c2164784703="">
                  <th _ngcontent-ng-c2164784703="" className="ng-star-inserted">
                    {" "}
                    Name
                  </th>
                  {/**/}
                  {/**/}
                  {/**/}
                  <th _ngcontent-ng-c2164784703="">M.Comm.</th>
                  <th _ngcontent-ng-c2164784703="">S.Comm. </th>
                  <th _ngcontent-ng-c2164784703="">T.Comm.</th>
                  <th _ngcontent-ng-c2164784703="">M.Comm.</th>
                  <th _ngcontent-ng-c2164784703="">S.Comm.</th>
                  <th _ngcontent-ng-c2164784703="">T.Comm.</th>
                </tr>
              </thead>
              <tbody _ngcontent-ng-c2164784703="" className="ng-star-inserted">
                <tr _ngcontent-ng-c2164784703="">
                  <td _ngcontent-ng-c2164784703="" />
                  {/**/}
                  <td
                    _ngcontent-ng-c2164784703=""
                    colSpan={3}
                    className="bg_green"
                  >
                    <b _ngcontent-ng-c2164784703="">
                      PAYMENT RECEIVING FROM (MILA HE)
                    </b>
                  </td>
                  <td _ngcontent-ng-c2164784703="" colSpan={3} className="bg_red">
                    <b _ngcontent-ng-c2164784703="">PAYMENT PAID TO (DIYA HE)</b>
                  </td>
                </tr>
                <tr _ngcontent-ng-c2164784703="" className="ng-star-inserted">
                  <td _ngcontent-ng-c2164784703="" className="ng-star-inserted">
                    <div _ngcontent-ng-c2164784703="" className="reset_btn">
                      {" "}
                      Jmd nisthu <br _ngcontent-ng-c2164784703="" /> (SDM763144){" "}
                      <button
                        _ngcontent-ng-c2164784703=""
                        type="button"
                        className="btn btn-primary"
                      >
                        Reset
                      </button>
                    </div>
                  </td>
                  {/**/}
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">6,332.89</td>
                  <td _ngcontent-ng-c2164784703="">71,423.52</td>
                  <td _ngcontent-ng-c2164784703="">77,756.41</td>
                </tr>
                <tr _ngcontent-ng-c2164784703="" className="ng-star-inserted">
                  <td _ngcontent-ng-c2164784703="" className="ng-star-inserted">
                    <div _ngcontent-ng-c2164784703="" className="reset_btn">
                      {" "}
                      Gulli bhai <br _ngcontent-ng-c2164784703="" /> (SDM330048){" "}
                      <button
                        _ngcontent-ng-c2164784703=""
                        type="button"
                        className="btn btn-primary"
                      >
                        Reset
                      </button>
                    </div>
                  </td>
                  {/**/}
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">504.00</td>
                  <td _ngcontent-ng-c2164784703="">4,910.33</td>
                  <td _ngcontent-ng-c2164784703="">5,414.33</td>
                </tr>
                <tr _ngcontent-ng-c2164784703="" className="ng-star-inserted">
                  <td _ngcontent-ng-c2164784703="" className="ng-star-inserted">
                    <div _ngcontent-ng-c2164784703="" className="reset_btn">
                      {" "}
                      Bpj aksh bhai <br _ngcontent-ng-c2164784703="" />{" "}
                      (SDM236311){" "}
                      <button
                        _ngcontent-ng-c2164784703=""
                        type="button"
                        className="btn btn-primary"
                      >
                        Reset
                      </button>
                    </div>
                  </td>
                  {/**/}
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">353.89</td>
                  <td _ngcontent-ng-c2164784703="">1,010.71</td>
                  <td _ngcontent-ng-c2164784703="">1,364.60</td>
                </tr>
                <tr _ngcontent-ng-c2164784703="" className="ng-star-inserted">
                  <td _ngcontent-ng-c2164784703="" className="ng-star-inserted">
                    <div _ngcontent-ng-c2164784703="" className="reset_btn">
                      {" "}
                      Bansal sonu bhai <br _ngcontent-ng-c2164784703="" />{" "}
                      (SDM491364){" "}
                      <button
                        _ngcontent-ng-c2164784703=""
                        type="button"
                        className="btn btn-primary"
                      >
                        Reset
                      </button>
                    </div>
                  </td>
                  {/**/}
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">67.95</td>
                  <td _ngcontent-ng-c2164784703="">1,746.01</td>
                  <td _ngcontent-ng-c2164784703="">1,813.96</td>
                </tr>
                {/**/}
              </tbody>
              {/**/}
              <tfoot _ngcontent-ng-c2164784703="">
                <tr _ngcontent-ng-c2164784703="" className="ng-star-inserted">
                  <td _ngcontent-ng-c2164784703="">Total</td>
                  {/**/}
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">0.00</td>
                  <td _ngcontent-ng-c2164784703="">7,258.73</td>
                  <td _ngcontent-ng-c2164784703="">79,090.57</td>
                  <td _ngcontent-ng-c2164784703="">86,349.30</td>
                </tr>
                {/**/}
              </tfoot>
              {/**/}
            </table>
          </div>
        </div>
      </div>
    </app-commission-lena-dena>
    {/**/}
  </div>
  
  );
}
