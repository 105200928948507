import { useToast } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { getCoinHistoryAPI, searchUserAPI } from "../service/AuthService";
import { useLocation, useParams } from "react-router-dom";
export default function AccountStatement() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState("ALL");
  const location = useLocation();
  const [filter, setFilter] = useState(null);
  const [commissionFilter]=useState(["commission", "loss", "profit", "winner"])
  const [userName, setUserName] = useState(
    location.state?.userName
      ? {
          value: location.state?.userName,
          label: location.state?.userName,
        }
      : null
  );
  const [fromDate, setFromDate] = useState(
    moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD")
  );

  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const toast = useToast();
  const getAccountStatement = async () => {
    let payload = {
      startDate: new Date(fromDate).getTime(),
      endDate: new Date(toDate).getTime(),
    };

    const { response, message, code } = await getCoinHistoryAPI(
      id,
      payload.startDate,
      payload.endDate
    );
    if (code == 200) {
      setData(
        response.coinHistory.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
    } else {
      toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const filterObjectsByKeywords = (array, keywords) => {
    if (!keywords) return array;
    if (keywords == "ACCOUNT")
      return array.filter((obj) => {
        return !commissionFilter?.some(
          (keyword) =>
            obj.description.toLowerCase().includes(keyword.toLowerCase())
        );
      });
    return array.filter((obj) => {
      return keywords?.some((keyword) =>
        obj.description.toLowerCase().includes(keyword.toLowerCase())
      );
    });
  };

  useEffect(() => {
    getAccountStatement();
  }, [page, limit, type, userName, fromDate, toDate, search]);
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
    
    <app-account-statement _nghost-ng-c1769554045="">
      <div _ngcontent-ng-c1769554045="" className="page_header">
        <h1 _ngcontent-ng-c1769554045="" className="page_heading">
          Account Statement
        </h1>
        <div _ngcontent-ng-c1769554045="" className="breadcrumb_block">
          <nav _ngcontent-ng-c1769554045="" aria-label="breadcrumb">
            <ol _ngcontent-ng-c1769554045="" className="breadcrumb">
              <li _ngcontent-ng-c1769554045="" className="breadcrumb-item">
                <a _ngcontent-ng-c1769554045="" href="#/dashboard">
                  Home
                </a>
              </li>
              <li
                _ngcontent-ng-c1769554045=""
                aria-current="page"
                className="breadcrumb-item active"
              >
                Account Statement
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div
        _ngcontent-ng-c1769554045=""
        className="Super_master_details_section agentledger_block"
      >
        <form
          _ngcontent-ng-c1769554045=""
          noValidate=""
          className="row g-lg-3 g-2 align-items-end mb-4 ng-pristine ng-valid ng-touched"
        >
          <div
            _ngcontent-ng-c1769554045=""
            className="col-6 col-xs-6 col-md-4 col-lg-2"
          >
            <div _ngcontent-ng-c1769554045="" className="input_block">
              <label _ngcontent-ng-c1769554045="" htmlFor="type">
                Account Type
              </label>
              <select
                _ngcontent-ng-c1769554045=""
                formcontrolname="account_type"
                className="form-control form-select ng-untouched ng-pristine ng-valid"
              >
                <option _ngcontent-ng-c1769554045="" value="All">
                  All
                </option>
                <option _ngcontent-ng-c1769554045="" value="BR">
                  Balance Report
                </option>
                <option _ngcontent-ng-c1769554045="" value="GR">
                  Game Report
                </option>
              </select>
            </div>
          </div>
          <div _ngcontent-ng-c1769554045="" className="col-6 col-md-4 col-lg-2">
            <div _ngcontent-ng-c1769554045="" className="input_block">
              <label _ngcontent-ng-c1769554045="" htmlFor="gamename">
                Game Name
              </label>
              <select
                _ngcontent-ng-c1769554045=""
                formcontrolname="game_type"
                name="gamename"
                id="gamename"
                className="form-control form-select ng-pristine ng-valid ng-touched"
              >
                <option _ngcontent-ng-c1769554045="" value="All">
                  All
                </option>
                {/**/}
                {/**/}
                {/**/}
              </select>
            </div>
          </div>
          <div _ngcontent-ng-c1769554045="" className="col-6 col-md-4 col-lg-2">
            <div
              _ngcontent-ng-c1769554045=""
              className="input_block position-relative"
            >
              <label _ngcontent-ng-c1769554045="">Search By Client Name</label>
              <input
                _ngcontent-ng-c1769554045=""
                type="text"
                name="MatchAmount"
                id="sessionAmount"
                autoComplete="OFF"
                autofocus=""
                className="form-control"
              />
              {/**/}
            </div>
          </div>
          <div _ngcontent-ng-c1769554045="" className="col-6 col-md-4 col-lg-2">
            <div _ngcontent-ng-c1769554045="" className="input_block">
              <label _ngcontent-ng-c1769554045="">From</label>
              <input
                _ngcontent-ng-c1769554045=""
                type="date"
                id="fromdate"
                formcontrolname="from_date"
                className="form-control ng-untouched ng-pristine ng-valid"
              />
            </div>
          </div>
          <div _ngcontent-ng-c1769554045="" className="col-6 col-md-4 col-lg-2">
            <div _ngcontent-ng-c1769554045="" className="input_block">
              <label _ngcontent-ng-c1769554045="">To</label>
              <input
                _ngcontent-ng-c1769554045=""
                type="date"
                id="todate"
                formcontrolname="to_date"
                className="form-control ng-untouched ng-pristine ng-valid"
              />
            </div>
          </div>
          <div _ngcontent-ng-c1769554045="" className="col-6 col-md-6 col-lg-2">
            <div _ngcontent-ng-c1769554045="" className="input_block">
              <button
                _ngcontent-ng-c1769554045=""
                type="button"
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        <div _ngcontent-ng-c1769554045="" className="custom_table">
          <div _ngcontent-ng-c1769554045="" className="right_search_box mb-3">
            <div _ngcontent-ng-c1769554045="" className="search_bar_right">
              <h3 _ngcontent-ng-c1769554045="" className="page_heading">
                Search:{" "}
              </h3>
              <div _ngcontent-ng-c1769554045="" className="input_with_close">
                <input
                  _ngcontent-ng-c1769554045=""
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                />
                <a _ngcontent-ng-c1769554045="" href="javascript:void(0)">
                  <svg
                    _ngcontent-ng-c1769554045=""
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      _ngcontent-ng-c1769554045=""
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div _ngcontent-ng-c1769554045="" className="table-responsive">
            <table _ngcontent-ng-c1769554045="" className="table">
              <thead _ngcontent-ng-c1769554045="">
                <tr _ngcontent-ng-c1769554045="">
                  <th _ngcontent-ng-c1769554045="">Date</th>
                  <th _ngcontent-ng-c1769554045="">Credit</th>
                  <th _ngcontent-ng-c1769554045="">Debit</th>
                  <th _ngcontent-ng-c1769554045="">Closing</th>
                  <th _ngcontent-ng-c1769554045="">Description</th>
                  <th _ngcontent-ng-c1769554045="">Fromto</th>
                </tr>
              </thead>
              <tbody _ngcontent-ng-c1769554045="">
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 18:11:31
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -30.9
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Namibia Women v UAE Women 33557352_93(8 over run NAM W)
                      Profit [ Winner : 59 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 18:10:26
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -103
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_269(32 over run WOL) Profit [
                      Winner : 189 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:56:58
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -51.5
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Namibia Women v UAE Women 33557352_92(7 over run NAM W)
                      Profit [ Winner : 57 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:53:30
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    38.2{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_268(31 over run WOL) Loss [
                      Winner : 183 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:53:03
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    247.52{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Namibia Women v UAE Women 33557352_1(6 over run NAM W) Loss
                      [ Winner : 53 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:51:48
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -62.23
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples
                      9991.232902100_bm1(Bookmaker) Loss [ Winner : Alleppey
                      Ripples ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:50:51
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -153.53
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_231(J Jose
                      run) Profit [ Winner : 9 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:50:11
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    136.54{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_266(30 over run WOL) Loss [
                      Winner : 174 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:44:41
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    97{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_265(29 over run WOL) Loss [
                      Winner : 160 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:41:40
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    19.4{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_264(28 over run WOL) Loss [
                      Winner : 150 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:38:29
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    158.82{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_252(K Hari
                      run) Loss [ Winner : 19 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:37:40
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -141.29
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_255(Fall of
                      7th wkt TR) Profit [ Winner : 69 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:36:53
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    34.92{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_3(15 over run
                      TR) Loss [ Winner : 72 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:36:29
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -51.5
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_99(14 over run
                      TR) Profit [ Winner : 68 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:35:29
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -51.5
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_263(27 over run WOL) Profit [
                      Winner : 145 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:35:19
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -44.71
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_262(26 over run WOL) Loss [
                      Winner : 141 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:27:17
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    46.52{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_98(13 over run
                      TR) Loss [ Winner : 65 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:26:56
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    359.3{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_260(25 over run WOL) Loss [
                      Winner : 136 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:22:21
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    20.37{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_97(12 over run
                      TR) Loss [ Winner : 50 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:21:35
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    58.35{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_259(24 over run WOL) Loss [
                      Winner : 119 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:18:50
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -51.5
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_96(11 over run
                      TR) Profit [ Winner : 46 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:18:15
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -30.9
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_258(23 over run WOL) Profit [
                      Winner : 113 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:17:09
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    1.76{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_246(M Akhil
                      run) Profit [ Winner : 7 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:16:35
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -177.93
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_249(Fall of
                      6th wkt TR) Profit [ Winner : 44 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:16:24
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    97{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_257(22 over run WOL) Loss [
                      Winner : 105 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:12:09
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -8.02
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_2(10 over run
                      TR) Profit [ Winner : 43 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:11:39
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    19.4{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_256(21 over run WOL) Loss [
                      Winner : 99 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:09:48
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -3.09
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_22(Fall of 5th
                      wkt TR) Profit [ Winner : 41 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:08:48
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -51.5
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_95(9 over run
                      TR) Profit [ Winner : 41 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:08:01
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -57.5
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_183(20 over run WOL) Profit [
                      Winner : 91 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:04:26
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -103
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_182(19 over run WOL) Profit [
                      Winner : 85 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 17:03:42
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    45.5{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_94(8 over run
                      TR) Profit [ Winner : 40 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:57:54
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -2.06
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_21(Fall of 4th
                      wkt TR) Profit [ Winner : 39 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:57:23
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -100.21
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_93(7 over run
                      TR) Profit [ Winner : 39 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:57:04
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    173.25{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_1(6 over run
                      TR) Loss [ Winner : 37 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:56:08
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    19.4{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_180(17 over run WOL) Loss [
                      Winner : 77 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:54:23
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    58.2{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_203(M Faizan run) Loss [ Winner :
                      34 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:52:53
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -51.5
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_179(16 over run WOL) Profit [
                      Winner : 72 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:52:32
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    193.05{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_177(15 over run WOL) Profit [
                      Winner : 72 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:52:16
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    1.99{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_176(14 over run WOL) Loss [
                      Winner : 65 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:52:02
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -103
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_175(13 over run WOL) Profit [
                      Winner : 60 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:51:42
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    19.4{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_174(12 over run WOL) Loss [
                      Winner : 59 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:51:01
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    106.7{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_173(11 over run WOL) Loss [
                      Winner : 51 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:48:20
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    45.41{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_20(Fall of 3rd
                      wkt TR) Profit [ Winner : 21 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:47:54
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -6.15
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_92(5 over run
                      TR) Profit [ Winner : 27 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:47:40
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -175.1
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_91(4 over run
                      TR) Profit [ Winner : 21 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:38:44
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -30.9
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_90(3 over run
                      TR) Profit [ Winner : 21 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:35:26
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    -2.06
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_18(Fall of 1st
                      wkt TR) Profit [ Winner : 11 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:35:06
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    101.85{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Trivandrum Royals v Alleppey Ripples 33577647_89(2 over run
                      TR) Loss [ Winner : 15 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                <tr _ngcontent-ng-c1769554045="" role="row">
                  <td _ngcontent-ng-c1769554045="" className="sorting_1">
                    <div _ngcontent-ng-c1769554045="" className="Date_time_box">
                      2024-09-12 16:22:25
                    </div>
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    3.7{" "}
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    0
                  </td>
                  <td _ngcontent-ng-c1769554045="" className="text-end">
                    {" "}
                    0.00
                  </td>
                  <td _ngcontent-ng-c1769554045="">
                    <a
                      _ngcontent-ng-c1769554045=""
                      type="button"
                      className="dis_txt"
                    >
                      {" "}
                      Wolves v Panthers 33578368_185(F Zaman run) Profit [ Winner
                      : 17 ]{" "}
                    </a>
                  </td>
                  {/**/}
                  {/**/}
                  <td _ngcontent-ng-c1769554045="">
                    <div _ngcontent-ng-c1769554045="" className="text_block_m">
                      /
                    </div>
                  </td>
                </tr>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </tbody>
            </table>
            <div
              _ngcontent-ng-c1769554045=""
              className="d-flex justify-content-end"
              style={{ textAlign: "center" }}
            >
              <div _ngcontent-ng-c1769554045="" className="custom-pagination">
                <button
                  _ngcontent-ng-c1769554045=""
                  className="pagination-previous"
                  disabled="true"
                >
                  Previous
                </button>
                <button _ngcontent-ng-c1769554045="" className="active">
                  1
                </button>
                {/**/}
                <button _ngcontent-ng-c1769554045="">2</button>
                {/**/}
                <button _ngcontent-ng-c1769554045="">3</button>
                {/**/}
                <button _ngcontent-ng-c1769554045="">4</button>
                {/**/}
                <button _ngcontent-ng-c1769554045="">5</button>
                {/**/}
                <button _ngcontent-ng-c1769554045="" className="ellipsis">
                  ...
                </button>
                {/**/}
                <button _ngcontent-ng-c1769554045="">46</button>
                {/**/}
                {/**/}
                <button _ngcontent-ng-c1769554045="" className="pagination-next">
                  Next
                </button>
              </div>
            </div>
            {/**/}
          </div>
        </div>
      </div>
      <div
        _ngcontent-ng-c1769554045=""
        id="ViewMoreModal"
        tabIndex={-1}
        aria-labelledby="ViewMoreModalLabel"
        aria-hidden="true"
        className="sitemoadal modal fade"
      >
        <div _ngcontent-ng-c1769554045="" className="modal-dialog modal-xl">
          <div _ngcontent-ng-c1769554045="" className="modal-content">
            <div _ngcontent-ng-c1769554045="" className="modal-header">
              <h5 _ngcontent-ng-c1769554045="" className="modal-title">
                Client Ledger (Total Win Loss : 0) (Total Count ) (Total Soda )
              </h5>
              <button
                _ngcontent-ng-c1769554045=""
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="btn-close"
              />
            </div>
            <div _ngcontent-ng-c1769554045="" className="modal-body">
              <ul
                _ngcontent-ng-c1769554045=""
                id="myTab"
                role="tablist"
                className="nav nav-tabs"
              >
                <li
                  _ngcontent-ng-c1769554045=""
                  role="presentation"
                  className="nav-item"
                >
                  <button
                    _ngcontent-ng-c1769554045=""
                    id="Matched-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Matched"
                    type="button"
                    role="tab"
                    aria-controls="Matched"
                    aria-selected="true"
                    className="nav-link active"
                  >
                    Matched
                  </button>
                </li>
                <li
                  _ngcontent-ng-c1769554045=""
                  role="presentation"
                  className="nav-item"
                >
                  <button
                    _ngcontent-ng-c1769554045=""
                    id="Deleted-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Deleted"
                    type="button"
                    role="tab"
                    aria-controls="Deleted"
                    aria-selected="false"
                    className="nav-link"
                  >
                    Deleted
                  </button>
                </li>
              </ul>
              <div
                _ngcontent-ng-c1769554045=""
                id="myTabContent"
                className="tab-content"
              >
                <div
                  _ngcontent-ng-c1769554045=""
                  id="Matched"
                  role="tabpanel"
                  aria-labelledby="Matched-tab"
                  className="tab-pane fade show active"
                >
                  <div _ngcontent-ng-c1769554045="" className="custom_table">
                    <div
                      _ngcontent-ng-c1769554045=""
                      className="table-responsive"
                    >
                      <table _ngcontent-ng-c1769554045="" className="table">
                        <thead _ngcontent-ng-c1769554045="">
                          <tr _ngcontent-ng-c1769554045="">
                            <th _ngcontent-ng-c1769554045="">Uplevel</th>
                            <th _ngcontent-ng-c1769554045="">userName</th>
                            <th _ngcontent-ng-c1769554045="">Nation</th>
                            <th _ngcontent-ng-c1769554045="">Side</th>
                            <th _ngcontent-ng-c1769554045="">Run/odds</th>
                            <th _ngcontent-ng-c1769554045="">Amount</th>
                            <th _ngcontent-ng-c1769554045="">Win / Loss</th>
                            <th _ngcontent-ng-c1769554045="">Place Date</th>
                            <th _ngcontent-ng-c1769554045="">Match Date</th>
                            <th _ngcontent-ng-c1769554045="">IP</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr _ngcontent-ng-c1769554045="">
                            <td
                              _ngcontent-ng-c1769554045=""
                              valign="top"
                              colSpan={10}
                              className="dataTables_empty"
                              style={{ textAlign: "center" }}
                            >
                              No data available in table
                            </td>
                          </tr>
                          {/**/}
                          {/**/}
                        </tbody>
                      </table>
                      {/**/}
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1769554045=""
                  id="Deleted"
                  role="tabpanel"
                  aria-labelledby="Deleted-tab"
                  className="tab-pane fade"
                >
                  <div _ngcontent-ng-c1769554045="" className="custom_table">
                    <div
                      _ngcontent-ng-c1769554045=""
                      className="table-responsive"
                    >
                      <table _ngcontent-ng-c1769554045="" className="table">
                        <thead _ngcontent-ng-c1769554045="">
                          <tr _ngcontent-ng-c1769554045="">
                            <th _ngcontent-ng-c1769554045="">Uplevel</th>
                            <th _ngcontent-ng-c1769554045="">userName</th>
                            <th _ngcontent-ng-c1769554045="">Nation</th>
                            <th _ngcontent-ng-c1769554045="">Side</th>
                            <th _ngcontent-ng-c1769554045="">Run/odds</th>
                            <th _ngcontent-ng-c1769554045="">Amount</th>
                            <th _ngcontent-ng-c1769554045="">Win / Loss</th>
                            <th _ngcontent-ng-c1769554045="">Place Date</th>
                            <th _ngcontent-ng-c1769554045="">Match Date</th>
                            <th _ngcontent-ng-c1769554045="">IP</th>
                          </tr>
                        </thead>
                        <tbody _ngcontent-ng-c1769554045="">
                          {/**/}
                          <tr _ngcontent-ng-c1769554045="">
                            <td
                              _ngcontent-ng-c1769554045=""
                              colSpan={10}
                              className="dataTables_empty"
                              style={{ textAlign: "center" }}
                            >
                              No records found
                            </td>
                          </tr>
                          {/**/}
                        </tbody>
                      </table>
                      <div
                        _ngcontent-ng-c1769554045=""
                        className="d-flex justify-content-center"
                        style={{ textAlign: "center" }}
                      >
                        {/**/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-account-statement>
    {/**/}
  </div>
  
  );
}
