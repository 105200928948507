import React, { useEffect, useState } from "react";
import { getUserDataAPI } from "../../service/UserService";
import store from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { APP_NAME, PERMISSION_MAP, PLATFORM } from "../../utils/constants";

export default function Sidebar() {
  const location = useLocation();
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const userData = store.getState().account.userData;
  const _isMounted = React.useRef(true);
  const getAccountDetails = async () => {
    const { response } = await getUserDataAPI(
      store.getState().account.userData.id
    );
    dispatch({
      type: "accountData/setUserProfile",
      payload: response,
    });
  };
  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (_isMounted.current) {
      setUserName(store.getState().account.userData.username);
      const interval = setInterval(() => {
        getAccountDetails();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, []);

  const [isMobileUI, setIsMobileUI] = useState(window.innerWidth < 768);
  useEffect(() => {
    function handleResize() {
      setIsMobileUI(window.innerWidth < 768);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const [currentActive, setCurrentActive] = useState(null);
  const [navs, setNavs] = useState([
    {
      name: "ADMIN DETAILS",
      isGroupTitle: true,
    },
    {
      url: "/admin",
      name: "Admin",
      icon: "nav-icon fas fa-address-card",
      enum: "ADMIN",
      isPermissionEnabled: true,
    },
    {
      url: "/sub_admin",
      name: "Sub Admin",
      icon: "nav-icon fas fa-address-card",
      enum: "SUB_ADMIN",
      isPermissionEnabled: true,
    },
    {
      url: "/master",
      name: "MasterAgent Master",
      icon: "nav-icon fas fa-address-card",
      enum: "MASTER",
      isPermissionEnabled: true,
    },
    {
      url: "/super_agent",
      name: "SuperAgent Master",
      icon: "nav-icon fas fa-address-card",
      enum: "SUPER_AGENT",
      isPermissionEnabled: true,
    },
    {
      url: "/agent",
      name: "Agent Master",
      icon: "nav-icon fas fa-address-card",
      enum: "AGENT",
      isPermissionEnabled: true,
    },
    {
      url: "/client",
      name: "Client Master",
      icon: "nav-icon fas fa-address-card",
      enum: "CLIENT",
      isPermissionEnabled: true,
    },

    {
      name: "GAMES",
      isGroupTitle: true,
    },

    {
      url: "/game/inPlay",
      name: "InPlay Game",
      icon: "nav-icon fas fa-play",
    },
    {
      url: "/game/completeGame",
      name: "Complete Game",
      icon: "nav-icon far fa-stop-circle",
    },

    {
      name: "CASINO",
      isGroupTitle: true,
    },

    {
      name: "Live Casino Position",
      url: "#",
      icon: "nav-icon fas fa-chart-line",
      subNavs: [
        {
          url: "/casino/casino-position/TEEN_20",
          name: " Live Teen20 Position",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/casino/casino-position/DRAGON_TIGER_20",
          name: "Live DragonTiger Position",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/casino/casino-position/LUCKY7",
          name: " Live Lucky7 Position",
          icon: "nav-icon fas fa-chart-bar",
        },
        {
          url: "/casino/casino-position/ABJ",
          name: " Live Andar Bahar Position",
          icon: "nav-icon fas fa-chart-bar",
        },
      ],
    },

    {
      url: "/casino/casino-details",
      name: "Casino Details",
      icon: "nav-icon fas fa-wallet",
    },
    {
      name: "MATKA",
      isGroupTitle: true,
      platformSpecific: "2exch",
    },

    {
      name: "Matka Inplay",
      url: "/matka/inplay",
      icon: "nav-icon fa fa-play",
      platformSpecific: "2exch",
    },
    {
      name: "Matka Complete",
      url: "/matka/complete",
      icon: "nav-icon fa fa-stop-circle",
      platformSpecific: "2exch",
    },
    {
      name: "LEDGER",
      isGroupTitle: true,
    },
    {
      url: "/ledger",
      name: "My Ledger",
      icon: "nav-icon fas fa-angle-right",
    },
    {
      url: `/ledger/${userData?.username}`,
      name: "Lena Dena",
      icon: "nav-icon fas fa-angle-right",
      state: { isLenaDena: true },
      isPermissionEnabled: false,
    },
    userData?.userType == "AGENT" && {
      url: `/agent/commission_lena_dena_report`,
      name: "Comm Lena Dena",
      icon: "nav-icon fas fa-angle-right",
    },

    {
      name: "CASH TRANSACTION",
      isGroupTitle: true,
    },

    {
      url: "/ct/client",
      name: "Debit/Credit Entry (C)",
      icon: "nav-icon fas fa-angle-right",
      enum: "CLIENT",
      isPermissionEnabled: true,
      refresh: true,
    },
    {
      url: "/ct/agent",
      name: "Debit/Credit Entry (A)",
      icon: "nav-icon fas fa-angle-right",
      enum: "AGENT",
      isPermissionEnabled: true,
      refresh: true,
    },
    {
      url: "/ct/super_agent",
      name: "Debit/Credit Entry (SA)",
      icon: "nav-icon fas fa-angle-right",
      enum: "SUPER_AGENT",
      isPermissionEnabled: true,
      refresh: true,
    },
    {
      url: "/ct/master",
      name: "Debit/Credit Entry (M)",
      icon: "nav-icon fas fa-angle-right",
      enum: "MASTER",
      isPermissionEnabled: true,
      refresh: true,
    },
    {
      url: "/ct/sub_admin",
      name: "Debit/Credit Entry (SUB)",
      icon: "nav-icon fas fa-angle-right",
      enum: "SUB_ADMIN",
      isPermissionEnabled: true,
      refresh: true,
    },
    {
      url: "/ct/admin",
      name: "Debit/Credit Entry (AD)",
      icon: "nav-icon fas fa-angle-right",
      enum: "ADMIN",
      isPermissionEnabled: true,
      refresh: true,
    },
    {
      name: "ALL LEDGER",
      isGroupTitle: true,
    },
    {
      url: "/ledger/client",
      name: "All Client Ledger",
      icon: "nav-icon fas fa-angle-right",
      enum: "CLIENT",
      isPermissionEnabled: true,
    },
    {
      url: "/ledger/agent",
      name: "All Agent Ledger",
      icon: "nav-icon fas fa-angle-right",
      enum: "AGENT",
      isPermissionEnabled: true,
    },
    {
      url: "/ledger/super_agent",
      name: "All Super Ledger",
      icon: "nav-icon fas fa-angle-right",
      enum: "SUPER_AGENT",
      isPermissionEnabled: true,
    },
    {
      url: "/ledger/master",
      name: "All Master Ledger",
      icon: "nav-icon fas fa-angle-right",
      enum: "MASTER",
      isPermissionEnabled: true,
    },
    {
      url: "/ledger/sub_admin",
      name: "All Sub Admin Ledger",
      icon: "nav-icon fas fa-angle-right",
      enum: "SUB_ADMIN",
      isPermissionEnabled: true,
    },
    {
      url: "/ledger/admin",
      name: "All Admin Ledger",
      icon: "nav-icon fas fa-angle-right",
      enum: "ADMIN",
      isPermissionEnabled: true,
    },

    {
      name: "REPORTS",
      isGroupTitle: true,
    },
    {
      name: "Reports",
      url: "#",
      icon: "nav-icon fa fa-book",
      subNavs: [
        {
          name: "Current Bets",
          url: "/report/current_bets",
          icon: "nav-icon fa fa-book",
        },
        {
          name: "Bet History",
          url: "/report/bet_history",
          icon: "nav-icon fa fa-book",
        },
        {
          name: "General Report",
          url: "/report/general_report",
          icon: "nav-icon fa fa-book",
        },
      ],
    },

    {
      name: "Data Reports",
      url: "#",
      icon: "nav-icon fas fa-database",
      isPermissionEnabled: true,
      subNavs: [
        {
          url: "/report/admin",
          name: "Admin Reports",
          icon: "nav-icon fas fa-th-list",
          enum: "ADMIN",
        },
        {
          url: "/report/sub_admin",
          name: "Sub Admin Reports",
          icon: "nav-icon fas fa-th-list",
          enum: "SUB_ADMIN",
        },
        {
          url: "/report/master",
          name: "Master Reports",
          icon: "nav-icon fas fa-th-list",
          enum: "MASTER",
        },
        {
          url: "/report/super_agent",
          name: "Super Reports",
          icon: "nav-icon fas fa-th-list",
          enum: "SUPER_AGENT",
        },
        {
          url: "/report/agent",
          name: "Agent Reports",
          icon: "nav-icon fas fa-th-list",
          enum: "AGENT",
        },
        {
          url: "/report/client",
          name: "Clients Reports",
          icon: "nav-icon fas fa-th-list",
          enum: "CLIENT",
        },
      ],
    },

    {
      name: "Login Reports",
      url: "#",
      icon: "nav-icon fas fa-sign-in-alt",
      isPermissionEnabled: true,
      subNavs: [
        {
          url: "/report/login/admin",
          name: "Admin Login Reports",
          icon: "nav-icon fas fa-clipboard-list",
          enum: "ADMIN",
        },
        {
          url: "/report/login/sub_admin",
          name: "Sub Admin Login Reports",
          icon: "nav-icon fas fa-clipboard-list",
          enum: "SUB_ADMIN",
        },
        {
          url: "/report/login/master",
          name: "Master Login Reports",
          icon: "nav-icon fas fa-clipboard-list",
          enum: "MASTER",
        },
        {
          url: "/report/login/super_master",
          name: "Super Login Reports",
          icon: "nav-icon fas fa-clipboard-list",
          enum: "SUPER_AGENT",
        },
        {
          url: "/report/login/agent",
          name: "Agent Login Reports",
          icon: "nav-icon fas fa-clipboard-list",
          enum: "AGENT",
        },
        {
          url: "/report/login/client",
          name: "Clients Login Reports",
          icon: "nav-icon fas fa-clipboard-list",
          enum: "CLIENT",
        },
      ],
    },
  ]);
  const sidebarRef = React.useRef();
  const closeSideDrawer = () => {
    document.body.classList.remove("sidebar-open");
    dispatch({
      type: "accountData/setIsSideBarOpen",
      payload: false,
    });
  };

  const handleMenuToggle = () => {
    document.body.classList.toggle("sidebar-open");
  };
  const currentUserType = userData?.userType;
  const isSideBarOpen = useSelector(
    (state) => state.account.userData.isSideBarOpen
  );
  const currentLvl = PERMISSION_MAP[currentUserType];
  useEffect(() => {
    //handle outside click
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSideDrawer();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  return (
    <div _ngcontent-ng-c3705319800="" className="side_nav">
      <app-sidenav _ngcontent-ng-c3705319800="" _nghost-ng-c2194352153="">
        <div _ngcontent-ng-c2194352153="" className="side_nav_inner">
          <ul
            _ngcontent-ng-c2194352153=""
            id="accordionExample"
            className="sideNav_menu"
          >
            <li _ngcontent-ng-c2194352153="" className="dash_nav_item">
              <a _ngcontent-ng-c2194352153="" href="/" className="">
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use
                      _ngcontent-ng-c2194352153=""
                      xlinkHref="#dashboard-interface"
                    />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  Dashboard{" "}
                </span>
              </a>
            </li>
            <li
              _ngcontent-ng-c2194352153=""
              id="headingOne"
              className="dash_nav_item"
            >
              <a
                _ngcontent-ng-c2194352153=""
                href="javascript:void(0);"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
                className="subMenuLink collapsed"
              >
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use
                      _ngcontent-ng-c2194352153=""
                      xlinkHref="#information"
                    />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  Admin Details{" "}
                </span>
                <span
                  _ngcontent-ng-c2194352153=""
                  className="arrowIconSubmenu"
                />
              </a>
              <ul
                _ngcontent-ng-c2194352153=""
                id="collapseOne"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
                className="subMenuSide collapse"
                style={{}}
              >
                {/**/}
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/sub_admin">
                    Sub Admin
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/master">
                    Master
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/super_agent">
                    Super Agent
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/agent">
                    Agent
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/client">
                    Client
                  </a>
                </li>
              </ul>
            </li>
            <li
              _ngcontent-ng-c2194352153=""
              id="headingTwo"
              className="dash_nav_item"
            >
              <a
                _ngcontent-ng-c2194352153=""
                href="javascript:void(0);"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                className="subMenuLink collapsed"
              >
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use _ngcontent-ng-c2194352153="" xlinkHref="#football" />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  Sport's Betting{" "}
                </span>
                <span
                  _ngcontent-ng-c2194352153=""
                  className="arrowIconSubmenu"
                />
              </a>
              <ul
                _ngcontent-ng-c2194352153=""
                id="collapseTwo"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
                className="subMenuSide collapse"
                style={{}}
              >
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/game/inPlay">
                    Current Sport's Details{" "}
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/game/completeGame">
                    Completed Sport's Details{" "}
                  </a>
                </li>
              </ul>
            </li>
            <li
              _ngcontent-ng-c2194352153=""
              id="headingLedger"
              className="dash_nav_item"
            >
              <a
                _ngcontent-ng-c2194352153=""
                href="javascript:void(0);"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseLedger"
                aria-expanded="true"
                aria-controls="collapseLedger"
                className="subMenuLink active"
              >
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use _ngcontent-ng-c2194352153="" xlinkHref="#file" />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  Ledger{" "}
                </span>
                <span
                  _ngcontent-ng-c2194352153=""
                  className="arrowIconSubmenu"
                />
              </a>
              <ul
                _ngcontent-ng-c2194352153=""
                id="collapseLedger"
                aria-labelledby="headingLedger"
                data-bs-parent="#accordionExample"
                className="subMenuSide collapse show"
                style={{}}
              >
                <li _ngcontent-ng-c2194352153="">
                  <a
                    _ngcontent-ng-c2194352153=""
                    routerlink="/ledger/myledger"
                    href="/ledger"
                  >
                    My Ledger
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ledger/sub_admin">
                    Sub Admin Ledger
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ledger/master">
                    Master Ledger
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ledger/super_agent">
                    Super Agent Ledger
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ledger/agent">
                    Agent Ledger
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ledger/client">
                    All Client Ledger
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a
                    _ngcontent-ng-c2194352153=""
                    routerlink="/ledger/commission-lena-dena"
                    href="/ledger/commission-lena-dena"
                  >
                    Comission Lena Dena
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a
                    _ngcontent-ng-c2194352153=""
                    routerlink="/ledger/totalProfit"
                    href="#/ledger/totalProfit"
                  >
                    Total Profit
                  </a>
                </li>
              </ul>
            </li>
            <li
              _ngcontent-ng-c2194352153=""
              className="dash_nav_item"
              tabIndex={0}
            >
              <a _ngcontent-ng-c2194352153="" href="javascript:void(0);">
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use _ngcontent-ng-c2194352153="" xlinkHref="#report" />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  All Sub Admin Report{" "}
                </span>
              </a>
            </li>
            <li
              _ngcontent-ng-c2194352153=""
              id="headingCash"
              className="dash_nav_item"
            >
              <a
                _ngcontent-ng-c2194352153=""
                href="javascript:void(0);"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseCash"
                aria-expanded="false"
                aria-controls="collapseCash"
                className="subMenuLink collapsed"
              >
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use _ngcontent-ng-c2194352153="" xlinkHref="#money" />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  Cash Transaction{" "}
                </span>
                <span
                  _ngcontent-ng-c2194352153=""
                  className="arrowIconSubmenu"
                />
              </a>
              <ul
                _ngcontent-ng-c2194352153=""
                id="collapseCash"
                aria-labelledby="headingCash"
                data-bs-parent="#accordionExample"
                className="subMenuSide collapse"
              >
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ct/client">
                    Debit/Credit Entry (C)
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ct/agent">
                    Debit/Credit Entry (A)
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ct/super_agent">
                    Debit/Credit Entry (SA)
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ct/master">
                    Debit/Credit Entry (M)
                  </a>
                </li>
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ct/sub_admin">
                    Debit/Credit Entry (SUB)
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/ct/admin">
                    Debit/Credit Entry (AD)
                  </a>
                </li>
                {/**/}
                {/**/}
              </ul>
            </li>
            {/**/}
            <li
              _ngcontent-ng-c2194352153=""
              id="headingReports"
              className="dash_nav_item"
            >
              <a
                _ngcontent-ng-c2194352153=""
                href="javascript:void(0);"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseReports"
                aria-expanded="false"
                aria-controls="collapseReports"
                className="subMenuLink collapsed"
              >
                <span _ngcontent-ng-c2194352153="" className="icon_holder">
                  <svg _ngcontent-ng-c2194352153="">
                    <use _ngcontent-ng-c2194352153="" xlinkHref="#report" />
                  </svg>
                </span>
                <span _ngcontent-ng-c2194352153="" className="title_dash_nav">
                  {" "}
                  Reports{" "}
                </span>
                <span
                  _ngcontent-ng-c2194352153=""
                  className="arrowIconSubmenu"
                />
              </a>
              <ul
                _ngcontent-ng-c2194352153=""
                id="collapseReports"
                aria-labelledby="headingReports"
                data-bs-parent="#accordionExample"
                className="subMenuSide collapse"
              >
                {/**/}
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/report/bet_history">
                    Account Statement
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a _ngcontent-ng-c2194352153="" href="/report/bet_history">
                    Bet History
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a
                    _ngcontent-ng-c2194352153=""
                    href="#/setting/current-bets"
                  >
                    Current Bets
                  </a>
                </li>
                <li _ngcontent-ng-c2194352153="">
                  <a
                    _ngcontent-ng-c2194352153=""
                    href="#/setting/report-details"
                  >
                    Report Details
                  </a>
                </li>
                {/**/}
                {/**/}
              </ul>
            </li>
          </ul>
        </div>
      </app-sidenav>
    </div>
  );
}
